import * as React from "react"
import { CredencialUsuarioScreen } from "../components/credencialUsuario/CredencialUsuarioScreen"
import Layout from '../components/layout'


// markup
const CredencialUsuario = () => {
  return (
    <Layout>
      <div className="container-xl">
        <CredencialUsuarioScreen />
      </div>
    </Layout>
  )
}

export default CredencialUsuario
