
import { types } from "../types/types";


//libroDigital
export const libroDigitalTablaAniosEscolares = () => ({ type: types.libroDigitalTablaAniosEscolares });
export const libroDigitalTablaCursos = () => ({ type: types.libroDigitalTablaCursos });
export const libroDigitalTablaEstudiantes = () => ({ type: types.libroDigitalTablaEstudiantes });
export const libroDigitalTablaAsignaturasCurso = () => ({ type: types.libroDigitalTablaAsignaturasCurso });
export const libroDigitalTablaAsignaturasCursoSegundo = () => ({ type: types.libroDigitalTablaAsignaturasCursoSegundo });
export const libroDigitalTablaPeriodos = () => ({ type: types.libroDigitalTablaPeriodos });

export const libroDigitalTablaAsignaturas = () => ({ type: types.libroDigitalTablaAsignaturas });
export const libroDigitalTablaClasesAsignatura = () => ({ type: types.libroDigitalTablaClasesAsignatura });
export const libroDigitalFormClaseAsignaturaNueva = () => ({ type: types.libroDigitalFormClaseAsignaturaNueva });
export const libroDigitalFormClaseAsignaturaActualizar = () => ({ type: types.libroDigitalFormClaseAsignaturaActualizar });
export const libroDigitalTablaCalificacionesAsignatura = () => ({ type: types.libroDigitalTablaCalificacionesAsignatura });

export const libroDigitalTablaTalleres = () => ({ type: types.libroDigitalTablaTalleres });
export const libroDigitalTablaClasesTaller = () => ({ type: types.libroDigitalTablaClasesTaller });
export const libroDigitalFormClaseTallerNueva = () => ({ type: types.libroDigitalFormClaseTallerNueva });
export const libroDigitalFormClaseTallerActualizar = () => ({ type: types.libroDigitalFormClaseTallerActualizar });
export const libroDigitalTablaCalificacionesTaller = () => ({ type: types.libroDigitalTablaCalificacionesTaller });

//libro digital modulo asistencia
export const libroDigitalAsistenciaScreen = () => ({ type: types.libroDigitalAsistenciaScreen });
export const libroDigitalAsistenciaRegistroScreen = () => ({ type: types.libroDigitalAsistenciaRegistroScreen });
export const libroDigitalAsistenciaInformeScreen = () => ({ type: types.libroDigitalAsistenciaInformeScreen });
export const libroDigitalTablaAsistencias = () => ({ type: types.libroDigitalTablaAsistencias });
export const libroDigitalTablaAsistenciaEstudiantes = () => ({ type: types.libroDigitalTablaAsistenciaEstudiantes });
export const libroDigitalFormAsistenciaRegistro = () => ({ type: types.libroDigitalFormAsistenciaRegistro});

export const libroDigitalInformeDetalleAsistenciaEstudiante = () => ({ type: types.libroDigitalInformeDetalleAsistenciaEstudiante});


// anotaciones

export const libroDigitalAnotacionScreen = () => ({ type: types.libroDigitalAnotacionScreen});