import React from 'react'
import { RowCredencialUsuario } from './RowCredencialUsuario'

export const TablaCredencialesUsuario = ({credencialesUsuario}) => {
    return (
        <table className='table table-bordered table-hover'>
            <thead>
                <tr>
                    <th>N°</th>
                    <th>Email</th>
                    <th>Cargo</th>
                    <th>Establecimiento</th>
                    <th>Estado</th>
                    <th>Ingresar</th>
                </tr>
            </thead>
            <tbody>
                {credencialesUsuario && credencialesUsuario.map((e,index) => (
                    <RowCredencialUsuario 
                        key={e._id}
                        credencial={e}
                        index={index + 1}
                    />

                ))}
            </tbody>
        </table>
    )
}
