import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux'
import { obtenerCredencialesUsuario } from '../../action/credencialesUsuarios';
import { obtenerEstablecimientos } from '../../action/establecimiento';
import { TablaCredencialesUsuario } from './TablaCredencialesUsuario';

export const CredencialUsuarioScreen = () => {
    const dispatch = useDispatch()
    const { uid, nombres } = useSelector( state => state.auth );
    const { credencialesUsuario } = useSelector( state => state.usuario)


    useEffect(() => {
        const filtro={
            usuario: uid
        }
        const obtenerEstablecimientosPrincipal = () => dispatch(obtenerEstablecimientos());
        const obtenerCredencialesUsuarioPrincipal = () => dispatch(obtenerCredencialesUsuario(filtro));
        obtenerEstablecimientosPrincipal()
        obtenerCredencialesUsuarioPrincipal()
        // eslint-disable-next-line
    }, []);

    if(!uid){
        setTimeout(() => {
          navigate("/login/")
        }, 1500);
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <h3>Credenciales Usuario: { nombres }</h3>
                <TablaCredencialesUsuario
                    credencialesUsuario={credencialesUsuario}
                />
            </div>
        </div>
    )
}
