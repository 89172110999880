import { fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';

export const crearEstablecimiento = ( establecimiento ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearEstablecimiento', establecimiento, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearEstablecimientoOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearEstablecimientoError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearEstablecimientoError());
        }
    }
}

const crearEstablecimientoOk = (e) => ({
    type: types.crearEstablecimientoOk,
    payload: e
});

const crearEstablecimientoError = () => ({ type: types.crearEstablecimientoError });


export const obtenerEstablecimientos = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('establecimiento/obtenerEstablecimientos');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerEstablecimientosOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerEstablecimientosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerEstablecimientosError());
        }
    }
}

const obtenerEstablecimientosOk = (e) => ({ 
    type: types.obtenerEstablecimientosOk ,
    payload: e
});

const obtenerEstablecimientosError = () => ({ type: types.obtenerEstablecimientosError });


export const actualizarEstablecimiento = (establecimiento) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`establecimiento/actualizarEstablecimiento/${establecimiento._id}`, establecimiento, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarEstablecimientoOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarEstablecimientoError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarEstablecimientoError())
        }
    }
}

const actualizarEstablecimientoOk = (e) => ({ 
    type: types.actualizarEstablecimientoOk,
    payload: e
});

const actualizarEstablecimientoError = () => ({ type : types.actualizarEstablecimientoError });

export const activarEstablecimiento = (e) => ({ 
    type: types.activarEstablecimiento,
    payload: e
});
