import React from 'react'
import { navigate } from "gatsby";
import { useDispatch } from 'react-redux';
import { activarCredencialUsuarioLogin } from '../../action/credencialesUsuarios';
import { libroDigitalTablaAniosEscolares } from '../../action/router';
import { useSelector } from 'react-redux';
import { getCargoUsuario } from '../../helpers/formatData';

export const RowCredencialUsuario = ({credencial, index}) => {
    const dispatch=useDispatch();
    const { establecimientos } = useSelector( state => state.establecimiento)
    const { email, cargo, estado } = credencial;
    const establecimiento = establecimientos.find(e=> e._id === credencial.establecimiento)

    const handleIngresar = () => {
        dispatch(activarCredencialUsuarioLogin(credencial._id))
        dispatch(libroDigitalTablaAniosEscolares())
        setTimeout(() => {
            navigate("/libroDigital/")
        }, 0);
    }
    return (
        <tr>
            <th>{index}</th>
            <td>{email}</td>
            <td>{getCargoUsuario(cargo)}</td>
            <td>{establecimiento.nombre}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' disabled >activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' disabled>inactivo</button>
                        </div>
                }
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm' onClick={handleIngresar} >Ingresar</button>
                </div>
            </td>
        </tr>
    )
}
